<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="收款编号：" prop="code">
                        <el-input v-model="m.code"></el-input>
                    </el-form-item>
                    <el-form-item label="收款名称：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>
                    <el-form-item label="收款金额：" prop="money">
                        <el-input v-model="m.money"></el-input>
                    </el-form-item>
                    <el-form-item label="甲方：" prop="firstName">
                        <el-input v-model="m.firstName"></el-input>
                    </el-form-item>
                    <el-form-item label="合同名称：" prop="contractName">
                        <el-input v-model="m.contractName"></el-input>
                    </el-form-item>
                    <el-form-item label="合同编号：" prop="contractCode">
                        <el-input v-model="m.contractCode"></el-input>
                    </el-form-item>
                    <el-form-item label="合同金额：" prop="contractAmount">
                        <el-input v-model="m.contractAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="总金额：" prop="amount">
                        <el-input v-model="m.amount"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <input-enum enumName="financeStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import SelectUser from '../../sa-resources/com-view/select-user.vue';
import SelelctProject from '../../sa-resources/com-view/selelct-project.vue';
export default {
    components: { inputEnum, SelectUser, SelelctProject },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                projectId: [],
                code: [{ required: true, message: '请输入收款编号', trigger: 'blur' },],
                name: [{ required: true, message: '请输入收款名称', trigger: 'blur' },],
                money: [{ pattern: /^\d{0,}$/, message: '收款金额必须为数字类型', trigger: 'blur' },],
                firstName: [],
                contractName: [],
                contractAmount: [{ pattern: /^\d{0,}$/, message: '合同金额必须为数字类型', trigger: 'blur' },],
                amount: [{ pattern: /^\d{0,}$/, message: '总金额必须为数字类型', trigger: 'blur' },],
                state: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 合同款";

                this.m = data;
            } else {
                this.m = {
                    projectId: data.projectId,
                    code: '',
                    name: '',
                    money: 0,
                    firstName: '',
                    contractName: '',
                    contractAmount: 0,
                    amount: 0,
                    state: 0
                }
                this.title = "添加 合同款";
            }
        },


        //提交合同款信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.sa.post("/finance/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>